import { MantineProvider, Box, Paper, CloseButton, Text, Loader, LoadingOverlay } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLocalStorage } from "usehooks-ts";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Anchor } from '@mantine/core';
import { Suspense } from "react";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        }
    }
})


function Banner() {
    const [showBanner, setShowBanner] = useLocalStorage("showBanner2", true)
    const [opened, { open, close }] = useDisclosure(false);
    if (!showBanner) {
        return null;
    }
    return (
        <>
            <Modal opened={opened} onClose={close} title="Learn More" >
                Our new form takes into account the following information to give you an accurate return date:
                <ul>
                    <li>The date the case will be sent to Oralart</li>
                    <li>The type of impression (physical or digital)</li>
                    <li>Shipping days to your region</li>
                    <li>Production days for the material and restoration selected</li>
                    <li>Current capacity in the laboratory</li>
                </ul>
            </Modal>
            <Box bg={"black"} py={"xs"} sx={{
                color: "white"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Box sx={{
                        flexGrow: 1
                    }} />
                    <Text px={"md"} onClick={open}>✨ Welcome to our new & improved pre-booking form. Accurate return dates for each product are now automatically calculated for you. <Anchor color={"white"} style={{
                        textDecoration: "underline"
                    }} underline={true}>Learn more</Anchor> ✨</Text>
                    <Box sx={{
                        flexGrow: 1
                    }} />
                    <CloseButton onClick={() => {
                        setShowBanner(false);
                    }} mr={"xs"} variant="filled" color="dark" aria-label="Close modal" />
                </Box>
            </Box>
        </>
    );
}


export function Base(props: { children: React.ReactNode }) {

    return (
        <QueryClientProvider client={queryClient}>
            <MantineProvider withGlobalStyles withNormalizeCSS>

                <Notifications />
                <Banner />
                <Box maw={700} mx="auto" mt={"md"} mb={"150px"} px={"sm"} >
                    <Suspense fallback={
                        <LoadingOverlay visible={true} overlayBlur={2} />
                    }>
                        <>
                            <Paper shadow="xs" p="md" radius={"md"}>
                                <img src="./oralart-logo.png" style={{ width: "100%", height: "auto" }} width="1600px" height="401px" />
                            </Paper>
                            {props.children}
                        </>
                    </Suspense>
                </Box>
            </MantineProvider>
        </QueryClientProvider >

    )
}