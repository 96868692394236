import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import { Base } from './Base.tsx';
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://3c85b8dd01654ed897632c7a04aa1723@o4505593993101312.ingest.sentry.io/4505594003521536",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://prebook.oralart.co.nz/"],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Base>
      <App />
    </Base>
  </React.StrictMode>,
)
