
function Tooth(props: {
  toothNum: number;
  textTransform: string;
  pathTransform: string;
  d: string;
} & TeethSelectionProps) {
  const selected = props.value.split(/[\s,]+/).map(x => parseInt(x)).includes(props.toothNum);

  return (
    <g
      style={{ fill: selected ? "#3a88d7" : "#e8e8e8", cursor: "pointer" }}
      id={`tooth-${props.toothNum}`}
      onClick={() => {
        if (selected) {
          // debugger;
          const splitValues = props.value.split(/[\s,]+/);
          const parsedValues = splitValues.map(x => x.trim());
          const noBlankValues = parsedValues.filter(x => x !== "");
          const filteredValues = noBlankValues.filter(t => t !== `${props.toothNum}`);
          const joinedValues = filteredValues.join(", ");

          props.setValue(joinedValues);
        } else {
          // debugger;
          const splitValues = props.value.split(/[\s,]+/);
          const parsedValues = splitValues.map(x => x.trim());
          const noBlankValues = parsedValues.filter(x => x !== "");
          const updatedValues = [...noBlankValues, props.toothNum];
          const joinedValues = updatedValues.join(", ");

          props.setValue(joinedValues);
        }
      }}
    >
      <path
        className="tooth_path"
        d={props.d}
        transform={props.pathTransform}
      ></path>
      <text
        style={{
          fontSize: "2.3em",
          fill: selected ? "white" : "#858585",
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
          userSelect: "none",
        }}
        textAnchor="middle"
        transform={props.textTransform}
      >
        {props.toothNum}
      </text>
    </g>
  );
}

type TeethSelectionProps = {
  onChange: (teeth: string) => void, value: string, setValue: (teeth: string) => void
}
function TeethSelection(props: TeethSelectionProps) {

  return (
    <>
      <div className="tooth_chart_container">
        <div className="tooth_chart">
          <span className="tooth_arch">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              viewBox="0 0 1305.645 223.836"
              style={{ maxHeight: "70px", maxWidth: "100%" }}
            >
              <title></title>
              <g className="upper_arch arch_wrapper">
                <Tooth
                  {...props}
                  toothNum={18}
                  textTransform="translate(38 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M110.186,100.161a68.839,68.839,0,0,1-2.1,9.207c-2.018,5.749-6.121,7.343-12.156,7.171a36.508,36.508,0,0,0-14.256,2.3,19.587,19.587,0,0,1-17.238-.644,29.249,29.249,0,0,0-7.863-2.686c-6.352-1.144-10.01-4.036-11.424-10.234a46.7,46.7,0,0,1-1.3-10.367c.031-17.62,8.973-27.614,26.419-29.779a41.525,41.525,0,0,1,6.468-.426C98.057,65.414,107.61,73.788,110.186,100.161Z"
                />
                <Tooth
                  {...props}
                  toothNum={17}
                  textTransform="translate(122 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M158.572,62.98c5.668.845,11.292,1.471,16.828,2.549,15.132,2.946,22.119,14.027,25.483,27.529a22.736,22.736,0,0,1-7.492,23.581c-7.414,6.181-16.591,5.875-25.112,2.36-5.98-2.467-11.287-3.307-17.741-1.537a29.623,29.623,0,0,1-15.632-.252c-9.41-2.843-17.915-10.294-16.04-22.618.424-2.79.669-5.615,1.23-8.376,2.42-11.912,8.539-20,21.638-21.093C147.354,64.656,152.934,63.712,158.572,62.98Z"
                />
                <Tooth
                  {...props}
                  toothNum={16}
                  textTransform="translate(215 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M301.022,100.277c-.763,11.162-5.517,16-15.964,16.211-3.549.072-7.187,1.31-10.575,2.576-8.765,3.278-16.862,1.9-24.982-2.269a12.987,12.987,0,0,0-8.256-1.113c-12.021,2.87-24.62-1.1-32-11.094-5.455-7.385-4.528-15.39-.833-23.182,7.147-15.067,21.667-22.585,38.146-20.433,10.869,1.419,21.791,2.571,32.725,3.316,7.669.523,11.981,5.2,14.371,11.369C296.735,83.621,298.62,92.049,301.022,100.277Z"
                />
                <Tooth
                  {...props}
                  toothNum={15}
                  textTransform="translate(302 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M307.075,95.426c1.585-5.169,2.539-9.021,3.941-12.7,3.186-8.375,6.907-16.557,9.821-25.021,3.037-8.819,6.345-12.211,14.354-12.957,8.81-.821,18.042,3.5,21.1,10.417,4.571,10.347,9.048,20.752,13.07,31.321,4.88,12.827,1.268,22.243-10.547,29.2-.573.337-1.165.644-1.748.967-23.023,12.746-25.6,8.744-42.928-7.13C310.378,106.074,309.186,99.831,307.075,95.426Z"
                />
                <Tooth
                  {...props}
                  toothNum={14}
                  textTransform="translate(377 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M384.513,97.292c1.393-6.1,2.606-11.095,3.661-16.121,1.264-6.028,2.714-12.042,3.5-18.137,1.224-9.437,7.29-13.745,15.681-15.809a25.839,25.839,0,0,1,21.094,3.429,20.071,20.071,0,0,1,7.2,9c3.232,8.54,5.492,17.446,8.205,26.186,2.236,7.206,1.4,13.987-3.49,19.749a212.718,212.718,0,0,1-14.745,15.51,10.164,10.164,0,0,1-6.244,2.667c-12.165.25-22.69-3.909-29.942-13.88C386.752,106.2,385.953,101.141,384.513,97.292Z"
                />
                <Tooth
                  {...props}
                  toothNum={13}
                  textTransform="translate(452 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M520.979,88.232c-.561,16.936-8.7,28.34-20.307,37.155-7.047,5.353-14.45.853-20.149-3.59A90.984,90.984,0,0,1,465.077,105.6c-4.842-6.246-4.72-13.81-2.308-21.036,3.477-10.415,7.222-20.756,11.318-30.94a27.765,27.765,0,0,1,6.424-9.348c6.792-6.519,16.841-6.051,23.006,1.509C513.884,58.5,519.073,73.488,520.979,88.232Z"
                />
                <Tooth
                  {...props}
                  toothNum={12}
                  textTransform="translate(528 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M580.6,119.866c-8.789-.752-17.583-1.434-26.363-2.267-9.017-.857-15.169-7.622-13.237-16.411,3.957-18,8.37-35.945,13.684-53.584,3.231-10.726,14.692-12.068,21.164-2.783a52.931,52.931,0,0,1,7.564,16.7c3.793,14.634,7.034,29.43,9.942,44.27C595.361,116.05,590.9,120.569,580.6,119.866Z"
                />
                <Tooth
                  {...props}
                  toothNum={11}
                  textTransform="translate(611 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M652.307,124.44c-9.463-.612-18.945-1.026-28.385-1.9-7.735-.714-11.21-4.03-11.565-11.771a51.553,51.553,0,0,1,1.63-15.747c4.867-17.749,12.46-34.405,21.781-50.253,6.622-11.259,17.514-12.437,26.287-2.727,2.516,2.786,5.048,6.079,6.1,9.582,5.479,18.3,10.972,36.624,15.473,55.179,2.755,11.356-2.608,17.045-14.3,17.248-5.661.1-11.324.017-16.985.017Z"
                />
                <Tooth
                  {...props}
                  toothNum={21}
                  textTransform="translate(695 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M729.968,124.045c-6.327,0-12.664.229-18.978-.054-9.182-.412-14.144-5.708-12.821-14.638a233.034,233.034,0,0,1,17.253-60.806,20.6,20.6,0,0,1,2.994-4.58c9.968-12.1,21.291-11.05,29.022,2.524,8.852,15.542,16.238,31.729,20.9,49.006a51.508,51.508,0,0,1,1.46,15.762c-.361,7.254-3.684,10.523-10.927,11.243-9.6.953-19.245,1.362-28.871,2.005Z"
                />
                <Tooth
                  {...props}
                  toothNum={22}
                  textTransform="translate(778 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M800.805,119.966c-9.251.68-14.129-3.582-12.241-12.717,3.76-18.2,8.582-36.174,13.084-54.212a20.4,20.4,0,0,1,2.743-5.859c3.076-4.806,6.89-8.772,13.234-8.171,6.038.573,8.986,4.952,10.365,10.128,4.533,17.014,9.087,34.036,12.941,51.21,2.093,9.328-3.864,16.429-13.272,17.311C818.714,118.5,809.757,119.2,800.805,119.966Z"
                />
                <Tooth
                  {...props}
                  toothNum={23}
                  textTransform="translate(852 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M921.034,93.32c-.735,3.033-.757,6.534-2.339,9.059-6.6,10.536-14.171,20.45-26.781,24.1-3.38.977-8.661.578-11.164-1.493-11.77-9.734-20.984-21.528-19.362-37.946a77.533,77.533,0,0,1,16.865-41.053c6.617-8.312,17.838-8.143,24.821-.111a31.069,31.069,0,0,1,5.377,8.915c3.695,9.455,7.092,19.032,10.385,28.636C919.9,86.537,920.293,89.885,921.034,93.32Z"
                />
                <Tooth
                  {...props}
                  toothNum={24}
                  textTransform="translate(930 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M996.952,96.363c-.33,12.216-6.821,20.055-21.666,25.656-7.97,3.007-17.5,3.913-23.4-5.641-2.465-3.99-6.857-6.736-9.992-10.377-5.378-6.245-5.985-13.442-3.472-21.114,2.174-6.64,4.366-13.282,6.275-20,4.232-14.887,16.954-21.7,31.882-17.055,7.686,2.393,12.439,6.946,13.757,15.286,1.322,8.365,3.543,16.586,5.285,24.888C996.2,90.764,996.515,93.576,996.952,96.363Z"
                />
                <Tooth
                  {...props}
                  toothNum={25}
                  textTransform="translate(1004 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1074.066,92.824c-.622,8.411-2.392,14.458-8.027,18.812-3.423,2.645-6.724,5.451-10.036,8.237-5.344,4.495-11.622,5.633-17.794,3.106a102.953,102.953,0,0,1-19.275-10.317c-7.838-5.4-10.485-13.667-7.371-22.518a312.577,312.577,0,0,1,14.846-35.951c4.054-8.119,12.474-9.972,21.118-9.281,7.712.616,11.313,5.524,13.585,12.591,2.736,8.511,6.6,16.654,9.776,25.032C1072.344,86.375,1073.327,90.394,1074.066,92.824Z"
                />
                <Tooth
                  {...props}
                  toothNum={26}
                  textTransform="translate(1089 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1082.17,100.939c.692-10.743,2.623-21.072,9-30.171,2.948-4.208,6.856-5.9,11.775-6.392,12.062-1.215,24.119-2.52,36.142-4.067,7.1-.913,13.482.053,19.292,4.55,7.195,5.568,13.821,11.5,16.769,20.507,3.221,9.837.685,18.044-7.528,24.3-8.266,6.294-17.148,9.14-27.576,5.859a10.481,10.481,0,0,0-7.363,1.1c-8.026,4.467-16.1,5.525-24.94,2.641a58.946,58.946,0,0,0-14.04-2.487C1086.594,116.281,1081.793,110.014,1082.17,100.939Z"
                />
                <Tooth
                  {...props}
                  toothNum={27}
                  textTransform="translate(1182  69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1225.112,62.894c6.708,1.233,14.689,2.138,22.294,4.342a19.154,19.154,0,0,1,9.567,7.092c5.509,7.931,7.177,17.3,5.975,26.732-1.17,9.172-8.171,14.193-16.3,16.316a35.416,35.416,0,0,1-16.614-.258c-5.445-1.315-9.861-.122-14.94,1.187-6.242,1.608-13.2,2.84-19.391,1.721-10.858-1.961-18-15.717-14.5-27.865,3.833-13.305,10.994-24.345,26.3-26.817C1212.913,64.47,1218.36,63.826,1225.112,62.894Z"
                />
                <Tooth
                  {...props}
                  toothNum={28}
                  textTransform="translate(1268 69)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1271.9,98.967a42.462,42.462,0,0,1,8.244-24.534,19.265,19.265,0,0,1,7.7-6.032c10.3-4.509,20.932-4.534,31.77-1.635,10.169,2.72,15.43,9.964,17.142,19.439,1.149,6.363.637,13.191-.149,19.688-.7,5.768-5.239,8.453-10.78,9.61a34.534,34.534,0,0,0-8.344,2.788,18.841,18.841,0,0,1-17.673.256,24.647,24.647,0,0,0-11.884-2.378c-9.56.758-13.57-1.676-15.4-11.072A58.171,58.171,0,0,1,1271.9,98.967Z"
                />
              </g>
              <g className="lower_arch arch_wrapper">
                <Tooth
                  {...props}
                  toothNum={38}
                  textTransform="translate(1268 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1307.885,236.053c-8.053-.952-16.961-2.007-25.869-3.057-6.227-.734-10.321-4.482-11.5-10.243-1.887-9.23-3.808-18.631-3.982-27.993-.321-17.292,16.338-27.648,31.646-19.76,6.127,3.158,10.591,2.911,16.5-.33,13.261-7.268,24.032-1.592,27.655,13.179,3.463,14.117.422,27.458-4.229,40.539-.871,2.45-4.663,4.912-7.461,5.421C1323.485,235.114,1316.121,235.322,1307.885,236.053Z"
                />
                <Tooth
                  {...props}
                  toothNum={37}
                  textTransform="translate(1182 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1179.969,204.249c0-3.66-.24-7.339.046-10.976.8-10.143,11.561-20.585,21.525-19.864,4.244.306,8.792,2.722,12.288,5.381,3.836,2.918,6.711,3.863,11.132,1.288a70.056,70.056,0,0,1,15.842-7.115c11.119-3.154,19.823,1.464,21.8,12.746,1.454,8.294.2,17.092-.143,25.653-.119,2.953-1.181,5.861-1.73,8.805a10.881,10.881,0,0,1-7.25,8.812c-21.2,7.766-42.618,8.408-64.144,1.555-4.81-1.531-7.664-5.27-8.234-10.36-.591-5.287-.91-10.6-1.348-15.908Z"
                />
                <Tooth
                  {...props}
                  toothNum={36}
                  textTransform="translate(1089 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1127.431,239.468c-9.375-.776-16.858-1.143-24.269-2.074-9.078-1.139-15.292-6.08-17.833-15.1-3.078-10.926-6.035-21.827-2.283-33.281,3.51-10.711,9.937-15.679,21.125-15.069a55.969,55.969,0,0,1,14.138,2.63,19.682,19.682,0,0,0,15.841-1.054c10.244-5.071,20.868-6.939,31.6-1.27,7.651,4.042,12.442,9.963,11.505,19.191s-1.467,18.538-3.082,27.651c-1.549,8.743-7.2,13.945-16.375,14.918C1147.07,237.148,1136.357,238.445,1127.431,239.468Z"
                />
                <Tooth
                  {...props}
                  toothNum={35}
                  textTransform="translate(1004 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1011.4,201.818c-1.627-13.931,7.31-21.237,17.485-27.249,10.6-6.264,21.255-4.922,31.716,1.244,10.135,5.974,14.69,14.518,11.983,25.961-2.328,9.84-4.949,19.741-8.794,29.056-3.993,9.674-13.87,10.753-22.791,11.749-12.146,1.356-19.1-2.579-23.536-13.892-3.353-8.558-5.216-17.7-7.743-26.58Z"
                />
                <Tooth
                  {...props}
                  toothNum={34}
                  textTransform="translate(930 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M1000.039,198.279a121.925,121.925,0,0,1-11.574,38.765c-3.842,7.933-19.41,12.3-29.642,8.408-4-1.52-8.326-4.176-10.72-7.572-6.949-9.856-11.9-20.793-13.155-33.029-2.206-21.523,22.781-40.829,42.751-32.485a49.934,49.934,0,0,1,16.707,12.119C997.564,187.9,998.249,193.6,1000.039,198.279Z"
                />
                <Tooth
                  {...props}
                  toothNum={33}
                  textTransform="translate(852 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M920.95,202.242c-1.174,4.338-3.053,12.946-5.872,21.234-2.836,8.337-6.531,16.388-9.991,24.5-2.84,6.664-7.795,11.03-15.213,10.994-7.336-.036-11.063-5.006-13.4-11.493-4.447-12.363-9.329-24.572-13.6-36.994-1.381-4.016-1.468-8.488-2.06-12.764-2.518-18.186,19.28-32.329,32.876-29.836C907.792,170.473,921.04,184.276,920.95,202.242Z"
                />
                <Tooth
                  {...props}
                  toothNum={32}
                  textTransform="translate(778 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M840.963,190.119c-2.456,15.835-7.936,34.292-14.708,52.394a16.89,16.89,0,0,1-2.993,4.571c-2.856,3.455-6.249,6.374-11.072,5.421-5-.988-7.332-4.989-8.5-9.53q-7.322-28.527-14.4-57.117a28.832,28.832,0,0,1-.891-6.905c0-6.631,3.453-9.681,10.072-9.184q14.939,1.123,29.878,2.25C839.325,172.85,841.912,175.823,840.963,190.119Z"
                />
                <Tooth
                  {...props}
                  toothNum={31}
                  textTransform="translate(695 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M735.49,172.109q4.249.007,8.5,0c11,.007,15.266,4.989,13.523,16.065-1.191,7.568-2.422,15.14-3.986,22.638-2.244,10.759-4.647,21.492-7.341,32.146a21.34,21.34,0,0,1-4.291,8.277c-3.619,4.27-8.02,8.059-14.23,6.154-6.436-1.974-8.451-7.385-9.335-13.522-1.685-11.694-3.773-23.334-5.268-35.052-1.045-8.187-2.179-16.5-1.823-24.692.4-9.162,4.39-11.96,13.754-12.021C728.491,172.084,731.991,172.1,735.49,172.109Z"
                />

                <Tooth
                  {...props}
                  toothNum={41}
                  textTransform="translate(611 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M646.89,172.107c4.165,0,8.338-.155,12.5.035,7.062.322,10.841,3.2,11.34,10.124a109.566,109.566,0,0,1-.568,21.779c-1.639,12.7-4.323,25.262-6.113,37.946-1.336,9.473-4.786,14.9-11.105,15.719-5.7.742-14.676-5.355-16.309-12.557-4.455-19.639-8.624-39.365-12.077-59.2-1.634-9.392,3.3-13.9,12.839-13.89q4.749.006,9.5,0Z"
                />
                <Tooth
                  {...props}
                  toothNum={42}
                  textTransform="translate(528 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M540.871,190.874c-.377-15.363,2.1-18.118,12.18-18.861,10.126-.746,20.252-1.5,30.372-2.318,6.884-.558,10.843,2.932,10.086,10.088a138,138,0,0,1-3.727,18.566c-3.608,14.534-7.437,29.013-11.168,43.517-1.258,4.892-3.3,9.432-8.711,10.537-5.742,1.173-9.943-2.432-12.172-6.935a132.593,132.593,0,0,1-8.416-21.791C545.713,211.254,542.836,198.621,540.871,190.874Z"
                />
                <Tooth
                  {...props}
                  toothNum={43}
                  textTransform="translate(452 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M460.888,198.118c-.9-15.076,9.3-22.172,20.56-27.9,10.526-5.351,20.207-2.317,29.269,4.63,10.657,8.171,12.81,19.237,9.395,30.892a445.4,445.4,0,0,1-16.4,45.487c-4.336,10.433-19.12,10.985-24.61.387C470.4,234.826,462.671,217.38,460.888,198.118Z"
                />

                <Tooth
                  {...props}
                  toothNum={44}
                  textTransform="translate(377 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M382.97,198.566c-.829-12.931,7.8-19.281,17.334-24.629,11.027-6.184,21.866-4.7,31.885,2.558,14.452,10.474,18.156,20.614,12.8,37.719a108.759,108.759,0,0,1-10.314,22.591c-5.023,8.493-13.6,10.91-22.991,9.681-9.073-1.187-17.289-4.075-20.345-14.181-2.211-7.312-4.747-14.533-6.719-21.906C383.6,206.58,383.491,202.518,382.97,198.566Z"
                />
                <Tooth
                  {...props}
                  toothNum={45}
                  textTransform="translate(302 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M370.708,205.437c-.032,7.917-3.1,18.531-8.571,28.48-3.676,6.681-10.233,8.831-16.959,8.559a64.345,64.345,0,0,1-19.33-4.073,15.059,15.059,0,0,1-7.757-7.884A155.425,155.425,0,0,1,309.81,203.9c-3.86-19.739,12.622-35.985,32.8-33.056C358.339,173.124,370.682,186.786,370.708,205.437Z"
                />
                <Tooth
                  {...props}
                  toothNum={46}
                  textTransform="translate(215 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M254.933,239.492c-9.852-1.142-21.25-2.331-32.608-3.828-7.153-.943-11.637-5.458-13.921-12.086-3.978-11.544-4.376-23.543-3.458-35.527.624-8.14,10.915-15.981,21.033-16.995a38.684,38.684,0,0,1,22.173,4.536c5.06,2.65,9.875,2.642,15.358,1a67.716,67.716,0,0,1,17.124-2.644c8.428-.273,14.127,4.392,17.176,12.061,5.793,14.567,2.722,28.734-4.022,41.819-2.2,4.272-8.415,7.695-13.446,9.006C272.73,238.815,264.547,238.612,254.933,239.492Z"
                />
                <Tooth
                  {...props}
                  toothNum={47}
                  textTransform="translate(122 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M202.267,203.743c-.481,5.464-.891,10.935-1.461,16.39-.585,5.6-3.891,9.078-9.12,10.681-20.384,6.248-40.658,5.611-60.875-.954-5.859-1.9-9.3-5.487-9.928-11.709-.9-8.907-2.821-17.824-2.649-26.7.308-15.868,11.318-22.953,26.315-17.611a72.693,72.693,0,0,1,12.923,6.405c3.752,2.261,6.863,2.152,9.823-.99,5.459-5.8,12.046-8.118,19.414-4.774,8.367,3.8,14.873,9.6,15.309,19.762.135,3.155.021,6.321.021,9.482Z"
                />
                <Tooth
                  {...props}
                  toothNum={48}
                  textTransform="translate(38 184)"
                  pathTransform="translate(-38.187 -35.477)"
                  d="M73.609,236.016c-8.153-.766-15.52-.993-22.669-2.363-2.754-.528-6.419-3.179-7.257-5.673-4.472-13.3-7.563-26.789-3.829-41.074,3.468-13.265,13.61-18.7,26.115-13.116a27.555,27.555,0,0,1,4.392,2.346c3.379,2.322,6.726,2.658,10.2.289a16.176,16.176,0,0,1,1.75-.959c16.743-8.6,33.637,1.364,33.176,20.182-.222,9.036-2.045,18.123-4.014,26.994-1.292,5.822-5.307,9.776-11.908,10.408C90.643,233.905,81.745,235.072,73.609,236.016Z"
                />
              </g>
            </svg>
          </span>
        </div>
      </div>
    </>
  );
}

export default TeethSelection;
