
import { Paper, Button, Text, Title } from '@mantine/core';
import { useMemo, useState } from 'react';
import urlJoin from 'url-join';
import { crownBridgePrebookingUrl, denturePrebookingUrl } from './functions';
import * as Sentry from "@sentry/browser";


async function getIdFromCancelReferenceId(baseUrl: string, cancelReferenceId: string) {
    const filterUrl = new URL(baseUrl);
    filterUrl.searchParams.append('filter[cancelReferenceId]', cancelReferenceId);
    const req = await fetch(filterUrl)
    const res = await req.json()
    const id = res.formResponses?.[0]?.id
    return [id, res.formResponses?.[0]] as const
}


async function cancelBooking(cancelReferenceId: string) {
    const crownBridgeIdReq = getIdFromCancelReferenceId(crownBridgePrebookingUrl, cancelReferenceId)
    const dentureIdReq = getIdFromCancelReferenceId(denturePrebookingUrl, cancelReferenceId)
    const [crownBridgeId, crownBridgeBody] = await crownBridgeIdReq
    const [dentureId, dentureBody] = await dentureIdReq

    if (crownBridgeId === undefined && dentureId === undefined) {
        throw new Error("No booking found with that cancelReferenceId")
    }

    let id = undefined
    let baseUrl = undefined
    let body = undefined
    if (crownBridgeId !== undefined) {
        id = crownBridgeId
        baseUrl = crownBridgePrebookingUrl
        body = crownBridgeBody
    } else {
        id = dentureId
        baseUrl = denturePrebookingUrl
        body = dentureBody
    }
    const editUrl = urlJoin(baseUrl, `${id}`)
    const editBody = {
        formResponse: {
            arrived: 'Cancelled online',
        }
    }
    const req2 = await fetch(editUrl, { method: "PUT", body: JSON.stringify(editBody), headers: { "Content-Type": "application/json", }, })
    if (!req2.ok) {
        throw new Error("Failed to update booking")
    }
    // send email to confirm cancellation


    const mailResponseRequest = fetch(`/.netlify/functions/submit-form`, { method: 'POST', body: JSON.stringify(body, null, 2), headers: { "Content-Type": "application/json", }, });
    await mailResponseRequest;
}

export function CancelPrebooking() {

    const cancelId = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('cancel')
    }, [])

    const invalidCancelId = cancelId === null || cancelId?.length !== 12

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const [hasError, setHasError] = useState(false);

    const [success, setSuccess] = useState(false);


    if (invalidCancelId) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Title size="h2" mb={"xs"}>Error</Title>
                <Text>
                    There was a problem with your cancellation link. Please contact us on&nbsp;
                    <a href="tel: 094783373">09 478 3373</a> or email{" "}
                    <a href="mailto: admin@oralart.co.nz">admin@oralart.co.nz</a>
                </Text>
            </Paper>
        )
    }

    if (hasError) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Title size="h2" mb={"xs"}>Error</Title>
                <Text>
                    There was a problem cancelling your pre-booking. Please contact us on&nbsp;
                    <a href="tel: 094783373">09 478 3373</a> or email{" "}
                    <a href="mailto: admin@oralart.co.nz">admin@oralart.co.nz</a>
                </Text>
            </Paper>
        )
    }

    if (success) {
        return (
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Text>
                    Your pre-booking has been cancelled
                </Text>
            </Paper>
        )
    }

    return (
        <>
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Text mb={"xs"}>Are you sure you want to cancel your pre-booking?</Text>
                <Button disabled={invalidCancelId} loading={isSubmitLoading} onClick={async () => {
                    try {
                        setIsSubmitLoading(true);
                        await cancelBooking(cancelId)
                        setSuccess(true)
                    } catch (e) {
                        Sentry.captureException(e)
                        setHasError(true)
                    } finally {
                        setIsSubmitLoading(false);
                    }

                }}>Yes, Cancel Pre-booking</Button>

            </Paper>
        </>
    );
}
