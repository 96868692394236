
export async function callSubmitForm(body: PrebookType) {
    const mailResponseRequest = fetch(`/.netlify/functions/submit-form`, { method: 'POST', body: JSON.stringify(body, null, 2), headers: { "Content-Type": "application/json", }, });
    const mailResponse = await mailResponseRequest;
    return mailResponse;
}

export type PrebookType = {
    timestamp?: string;
    emailAddress?: string;
    dentistPracticeName?: string;
    patientName?: string;
    returnDate: string;
    dateSend?: string;
    impressionType?: string;
    teethToBeRestored?: string;
    restorationType?: string;
    materialType?: string;
    comments?: string;
    bookingMadeBy?: string;
    referenceId?: string;
    cancelReferenceId?: string;
    dentureWhatItem?: string;
    dentureUpperOrLower?: string;
    dentureTryInOrStraitToFinish?: string;
    type: "Prebook" | "Cancel" | string;
    region: string;
    isCrownAndBridge: boolean;
    acceptedDisclaimers: string;
}
